/* **** Monocartons ***** */
@media (min-width:1440px) {
  .new-mono-item-card {
    /* height: 480px; */
  }
}

@media (min-width:1270px) and (max-width:1440px) {
  .new-mono-item-card {
    /* height: 460px; */
  }
}

@media (max-width:1270px) and (min-width:1200px) {
  .new-mono-item-card {
    /* height: 435px; */
  }
}

@media (max-width:1199px) and (min-width:1170px) {
  .new-mono-item-card {
    /* height: 505px !important; */
  }
}

@media (max-width:1170px) and (min-width:1090px) {
  .new-mono-item-card {
    /* height: 490px !important; */
  }
}

@media (max-width:1088px) and (min-width:992px) {
  .new-mono-item-card {
    /* height: 460px !important; */
  }
}

@media (max-width:500px) {
  .mono-cartons-products .product-cat-box-txt span {
    color: #000;
    font-size: 14px !important;
    width: 100%;
    display: inline-block;
    text-align: justify;
  }

  .mono-subheading-phone {
    font-size: 15px;
  }

  .mono-cartons-why-choose-icon .product-cat-box-img img {
    width: 25% !important;
    height: 100% !important;
    object-fit: contain;
    transform: scale(1);
  }
}

@media (min-width:500px) {
  .mono-cartons-why-choose-icon .product-cat-box-img img {
    width: 100% !important;
    height: 60% !important;
    object-fit: contain;
    transform: scale(1);
  }
}

.mono-cartons-why-choose-icon .product-cat-box-img {
  display: flex;
  align-items: center;
}



.mono-cartons-why-choose-icon .product-cat-box-txt {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.mobilenum-start-design {
  border-top-left-radius: 4px !important;
  border-bottom-left-radius: 4px !important;
}

.mobilenum-input-design {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.mono-form-heading-font {
  font-size: 17px !important;
}

.mono-font-weight {
  font-weight: bolder !important;
}

.open-btn:focus {
  outline: none !important;
}

.mono-cartons-inner-page-cont ul li {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.mono-cartons-inner-page-cont ul li p {
  margin-bottom: 2px;
}

.text-theme {
  color: #00aced;
  font-family: inherit;
  font-weight: bolder !important;
  margin-bottom: 0 !important;
}

.mono-cartons-inner-page-cont ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.mono-cartons-inner-page-cont ul li {
  width: 30%;
  margin-bottom: 10px;
  height: 100%;
  margin-left: 0;
}

.mono-cartons-inner-page-cont ul li b {
  display: block;
  color: #00aced;
  font-size: 1.1rem;
  font-weight: bolder !important;
}

.mono-cartons-inner-page-cont .bottom-content {
  display: flex;
  margin-bottom: 3px;
}

.mono-cartons-inner-page-cont .bottom-content b {
  color: #00aced;
  font-size: 1.1rem;
  font-weight: bolder !important;
  margin-right: 10px;
  /* min-width: 15%; */
}

.mono-cartons-inner-page-cont ul li i {
  display: none;
}

.mono-cartons-products .product-cat-box-img {
  overflow: hidden;
}

.mono-cartons-products .product-cat-box-img img {
  transform: scale(1.2);
}

.mono-cartons-products .product-cat-box-txt p {
  text-align: center;
  /* color: #00aced; */
  color: #000 !important;
  font-size: 1.1rem;
  margin: 5px 0;
}

.mono-cartons-products .product-cat-box-txt span {
  color: #51585f !important;
  /* font-size: .9rem; */
  width: 100%;
  display: inline-block;
  text-align: justify;
}

.why-choose-cards .product-cat-box {
  display: flex;
  gap: 10px;
}

.why-choose-cards .product-cat-box .product-cat-box-img {
  width: 30%;
}

.why-choose-cards .product-cat-box .product-cat-box-img img {
  height: 100%;
  object-fit: contain;
  transform: scale(1);
}

.why-choose-cards .product-cat-box .product-cat-box-img::before {
  display: none;
}

.why-choose-cards .product-cat-box .product-cat-box-txt {
  width: 70%;
  padding-right: 5px;
}

/* Basic styles for the Offcanvas */
.mono-carton-form .offcanvas {
  position: fixed;
  top: 0;
  right: -100%;
  /* Initially hidden outside the viewport */
  width: 50%;
  height: 100%;
  background-color: #fff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease;
  z-index: 1000;
  overflow-y: auto;
}

.mono-carton-form .offcanvas.show {
  right: 0;
  /* Slide in from the right */
}

/* Header styles */
.mono-carton-form .offcanvas-header {
  padding: 0 16px;
  background-color: #59c5d1;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
}

.mono-carton-form .offcanvas-body {
  /* padding: 16px; */
}

/* Overlay that darkens the background */
.mono-carton-form .overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

/* Button to open the Offcanvas */
.open-btn {
  padding: 10px 20px;
  background-color: #00aced !important;
  border-radius: 5px;
  position: fixed;
  right: 1rem;
  /* bottom: 180px; */
  bottom: 385px;
  z-index: 1;
  color: white;
  font-weight: bold;
  border: none;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 12px;
  /* margin: 20px; */
}




/* Close button inside the Offcanvas */
.close-btn {
  background: none;
  border: none;
  font-size: 3rem;
  height: auto;
  padding: 0;
  color: white;
  cursor: pointer;
}

.enquiry-form {
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
}

.enquiry-form h2 {
  text-align: center;
  color: #00aced;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.enquiry-form .form-section {
  margin-bottom: 20px;
}

.enquiry-form label {
  display: block;
  margin-bottom: 10px;
  font-weight: normal;
  color: #333;
}

.enquiry-form input[type="text"],
.enquiry-form input[type="number"],
.enquiry-form input[type="email"] {
  width: 100%;
  padding: 10px;
  /* margin-bottom: 15px; */
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 14px;
  box-sizing: border-box;
}

.enquiry-form h3 {
  /* color: #00aced; */
  color: #212529;
  margin-bottom: 10px;
}

.enquiry-form .carton-selection {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  overflow-x: auto;
  padding-bottom: 15px;
}

.enquiry-form .carton-card {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin: 0 5px;
  position: relative;
  background-color: #fff;
}

.enquiry-form .carton-card.selected {
  /* background-color: #e0f7fa; */
  border-color: #00aced;
  border: 1px solid #00aced;
  box-shadow: 0px 0px 2px 1px #00aced;
}

.enquiry-form .carton-card img {
  max-width: 100px;
  aspect-ratio: 1/1;
  margin-bottom: 10px;
}

.enquiry-form .carton-card p {
  font-weight: bold;
  color: #555;
  font-size: 13px;
}

.enquiry-form .carton-card input[type="checkbox"] {
  position: absolute;
  top: 10px;
  right: 10px;
}

.enquiry-form .carton-size {
  display: flex;
  justify-content: space-between;
}

.enquiry-form .carton-size label {
  flex: 1;
  margin-right: 10px;
}

.enquiry-form .carton-size label:last-child {
  margin-right: 0;
}

.enquiry-form .form-section button {
  /* width: 100%; */
  padding: 12px;
  background-color: #00aced !important;
  border: none;
  border-radius: 4px;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.enquiry-form .form-section button:hover {
  background-color: #006f9a;
}

.enquiry-form .bottom-images {
  display: flex;
  justify-content: space-between;
}

.enquiry-form .bottom-images img {
  width: 32%;
  border-radius: 5px;
  aspect-ratio: 1/1;
  object-fit: contain !important;
}


.mono-cartons-class .whatsapp-icon {

  bottom: 230px;

}

.mono-cartons-class .call-icon {

  bottom: 300px;
}

.whatsapp-icon {

  bottom: 230px;

}

.call-icon {

  bottom: 300px;
}

.inner-page-cont-para p{
  margin-bottom: 15px;
}
@media (min-width: 992px) {
  .inner-page-cont-para {
    padding-right: 10rem;
  }
}
@media (max-width: 568px) {
  .mono-cartons-inner-page-cont ul li {
    width: 100%;
  }

  .mono-cartons-inner-page-cont .bottom-content {
    flex-wrap: wrap;
  }

  .why-choose-cards .product-cat-box {
    flex-direction: column;
  }

  .why-choose-cards .product-cat-box .product-cat-box-img,
  .why-choose-cards .product-cat-box .product-cat-box-txt {
    width: 100%;
    padding-right: 0;
  }

  .mono-carton-form .offcanvas {
    width: 100%;
  }

  .enquiry-form .carton-selection {
    flex-wrap: wrap;
  }

  .enquiry-form .carton-card {
    max-width: 50%;
    margin-bottom: 10px;
  }

  .open-btn {
    bottom: 80px;
    left: 50%;
    right: inherit;
    width: 70%;
    transform: translateX(-50%);
  }
}


.mono-carton-box {
  position: relative !important;
}

.mono-type-content {
  background: #00aced;
  border-radius: 5px !important;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3px;
}

.new-mono-item-card {
  height: 100% !important;
  margin-bottom: 0 !important;
}

.mono-cartons-products .new-mono-item-card .product-cat-box-txt {
  padding-bottom: 10px !important;
}


@media (min-width:1582px) {


  .mono-margin-btn {
    margin-bottom: 30px !important;
  }
}

@media (max-width:992px) and (min-width:576px) {
  .product-cat-box {
    height: 100%;
  }
}






.quantity-icon {
  position: fixed;
  max-width: 150px;
  right: 1rem !important;
  float: right;
  z-index: 1;
  bottom: 55px;
  top: inherit;
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 #efefef;
  }

  5% {
    transform: scale(1.1);
  }

  15% {
    transform: scale(1);
  }

  70% {
    box-shadow: 0 0 0 50px rgba(168, 63, 57, 0);
  }

  100% {
    box-shadow: 0 0 0 100px rgba(168, 63, 57, 0);
  }
}

@media (max-width: 568px) {
  .quantity-icon {
    /* top: 250px; */
    top: 210px;
  }




}


@media (max-width: 600px) {
  .quantity-icon {
    max-width: 90px;
    top: inherit !important;
    bottom: 155px !important;
    right: 1rem !important;

  }

  .whatsapp-icon {

    bottom: 270px;

  }

  .call-icon {

    bottom: 340px;
  }

  .mono-cartons-class .whatsapp-icon {
    bottom: 300px;
  }

  .mono-cartons-class .call-icon {
    bottom: 390px;
  }

}