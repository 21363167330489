@media (min-width:500.9px) {
    .phn-view-shop-order {
        display: none !important;
    }
}

@media (max-width:500.9px) {
    .desktop-view-shop-order {
        display: none !important; 
    }

    .card-left-img-shop-outer {
        height: 200px;
    }

    .card-right-img-shop-outer {
        height: 200px;
        overflow-y: auto;
    }

    .main-img-size-height {
        height: 100% !important;
        width: 100% !important;
    }
}

.main-img-size {
    width: 100%;
    height: 300px;
}

@media (max-width:430px) {

    .cardtextactive h6,
    .cardtext h6 {
        font-size: 15px !important;
        text-align: left;
        padding: 0px 5px;
    }
}

.common-coat-img-div {
    width: 50px;
    height: 50px;
    text-align: center;
}

.coatinginput-size {
    width: 100%;
    border: 1px solid #ececec;
    border-radius: 8px;
    padding: 10px;
    box-shadow: 0 2px 3px rgb(0 0 0 / 8%);
}

/* *** Drop down Image sSize ***** */
.inner-coating-img-dropdown-size {
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    object-fit: cover;
}

.info-icon-card {
    cursor: pointer;
    float: right;
    font-size: 15px;
}

.coatingdropdownbtn {
    text-align: left;
    display: flex;
    align-items: center;
    background: transparent !important;
    border: 1px solid #59c5d1;
    border-radius: 8px;
    box-shadow: 0 2px 3px rgb(0 0 0 / 8%);
    color: #212529 !important;
    padding: 15px 5px;
    font-weight: 600;
}

.coatingdropdownbtn:not(:disabled):not(.disabled).active,
.coatingdropdownbtn:not(:disabled):not(.disabled):active,
.show>.coatingdropdownbtn.dropdown-toggle {
    color: #212529 !important;
    background-color: transparent !important;
    box-shadow: 0 2px 3px rgb(0 0 0 / 8%);
}

.coatingdropdownbtn:focus {
    box-shadow: none !important;
}

.inner-coat-drop {
    white-space: break-spaces !important;
}

.coating-drop-menu-parent {
    max-height: 270px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-top: 0 !important;
    width: 100% !important;
    /* border-radius: 10px; */
    background: #f7f7f7;
    box-shadow: 0px 0px 2px 0px #59c5d1;
}

.coat-drop-menu-active-bg {
    padding: 5px;
    background: #59c5d1;
    color: white;
}

.chips-fontsize {
    font-weight: 600;
}

.detailsicon .fa-info-circle {
    font-size: 15px;
    color: red !important;
    border-radius: 50px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
}

.img-drop-item-coating {
    height: 100% !important;
}


.coating-drop-menu-parent::-webkit-scrollbar-thumb {
    /* -webkit-border-radius: 10px; */
    /* border-radius: 10px; */
    background: transparent;
}

.coating-drop-menu-parent:-webkit-scrollbar-track {
    /* border-radius: 10px; */
    background-color: transparent;
}

.coating-drop-menu-parent::-webkit-scrollbar {
    width: 4px;
    background-color: #59c5d1;
    /* border-radius: 10px; */
}

.drop-product-price-size {
    width: 100% !important;
}

.productdetailspoints {
    line-height: 25px !important;
    color: black;
}

.productdetailspoints ul li>span>span>span :before {
    content: "\2713";
    font-size: 15px;
    margin-left: 0px;
    margin-right: 5px;
    font-weight: 600;
    color: #212529 !important;
}

.productdetailspoints ul li {
    line-height: 30px;
    list-style: none;
    display: flex;
}


.phnview-pickup-main-box .shipping {
    text-align: left;
    display: flex;
    align-items: center;
    background: transparent !important;
    border: 1px solid #59c5d1;
    border-radius: 8px;
    box-shadow: 0 2px 3px rgb(0 0 0 / 8%);
    color: #212529 !important;
    padding: 15px 5px;
}

.phnview-pickup-main-box select.form-control:not([size]):not([multiple]) {
    height: 50px !important;
}

.coast-hr-color {
    width: 90%;
    border: 1px solid rgba(0, 0, 0, .1);
}

.chips-position {
    align-items: center;
    display: flex;
    /* border-radius: 10px; */
    height: 50px;
    justify-content: space-between;
    border: 1px solid #59c5d1;
}

.price-chips-position {
    text-align: start;
    justify-content: space-between;
    border: 1px solid #59c5d1;
    /* border-radius: 10px; */
    font-size: 12px;
    height: 70px;
}

.price-chips-position h6 {
    font-size: 12px;
}

.main-parent-div-chips {
    /* display: flex;
    flex-wrap: nowrap;
    overflow-x: scroll; */
    padding-bottom: 20px;
}

.main-parent-div-chips::-webkit-scrollbar-thumb {
    /* -webkit-border-radius: 10px; */
    /* border-radius: 10px; */
    background: #59c5d1;
}

@media (max-width:500px) and (min-width:350px) {
    .price-seperate-box {
        border: 1px solid #59c5d1;
        height: 20px;
    }
}

@media (max-width:350px) and (min-width:200px) {
    .price-seperate-box {
        border: 1px solid #59c5d1 !important;
        height: 40px;
    }

    .extra-price-div-quantity {
        height: 40px !important;
    }
}

.quantity-input-phn {
    border: none !important;
}

.price-seperate-box-bottom {
    border: 1px solid #59c5d1;
    height: 40px;
    display: flex;
    align-items: center;
}

.main-parent-div-chips:-webkit-scrollbar-track {
    /* border-radius: 10px; */
    background-color: transparent;
}

.main-parent-div-chips::-webkit-scrollbar {
    height: 5px;
    background-color: transparent;
}

.pointplaceorder ul li {
    line-height: 30px;
    list-style: none;
    display: flex;
}

@media (max-width:500px) and (min-width:345px) {
    .shop-phn-img-heading {
        font-size: 16px;
        align-items: center;
        display: flex;
        text-align: start;
        overflow-wrap: anywhere;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
    }

    .product-phn-img-heading {
        font-size: 11px;
        align-items: center;
        display: flex;
        text-align: start;
        overflow-wrap: anywhere;
    }

    .price-shop-new {
        font-size: 11px;
        overflow-wrap: anywhere;
        font-family: "montserratultra_light";
        font-weight: 600;
    }
}

@media (max-width:345.9px) and (min-width:200px) {
    .shop-phn-img-heading {
        font-size: 12px !important;
        height: 3em !important;
        align-items: center;
        display: flex;
        overflow-wrap: anywhere;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
    }

    .price-shop-new {
        font-size: 12px;
    }

    .quantity-input-new-box {
        font-size: 12px !important;
        border: none;
    }

    .price-shop-new {
        font-size: 10px;
        overflow-wrap: anywhere;
    }

    .icon-outer-details {
        font-size: 13px !important;
        color: red !important;
    }
}

@media (max-width:345.9px) and (min-width:320px) {
    .product-phn-img-heading {
        font-size: 12px !important;
        height: 4em !important;
        align-items: center;
        display: flex;
        overflow-wrap: anywhere;
        overflow: hidden;
    }
}

@media (max-width:320.9px) {
    .product-phn-img-heading {
        font-size: 10px !important;
        height: 4em !important;
        align-items: center;
        display: flex;
        overflow-wrap: anywhere;
        overflow: hidden;
    }
}

@media (max-width:500px) and (min-width:345px) {
    .shop-phn-btn {
        font-size: 10px !important;
    }
}

@media (max-width:345.9px) and (min-width:200px) {
    .shop-phn-btn {
        font-size: 8px !important;
    }
}

.product-description-color {
    color: #212529 !important;
}

.product-content-main-box {
    position: relative;
}

.info-icon-product-outer {
    position: absolute;
    top: 5px;
    right: 1px;
}

.icon-outer-details {
    color: red !important;
}

.self-pickup-size {
    padding: 5px;
    border: 1px solid #59c5d1;
    font-size: 12px;
}

@media (max-width:360px) {
    .self-pickup-size {
        padding: 5px;
        border: 1px solid #59c5d1;
        font-size: 12px;
    }
}

.extra-price-div-quantity {
    height: 40px;
}



.eachsmall {
    visibility: hidden !important;
}

.price-heading-product {
    height: auto !important;
}

.phn-goback-bottom {
    margin-bottom: 70px !important;
}

.select-variant-name-font {
    font-size: 11px !important;
}

.new-product-page-item-content h6 {
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
}

.small-rigth-product-images-size {
    height: 100%;
}

.small-rigth-product-images-size img {
    width: 100%;
    height: 100%;
}

.right-product-image-main-box {
    height: 50% !important;
}

.content-whtasapp-font {
    font-size: 10px;
}

.whtasapp-send-message-font {
    padding: 0;
    margin-top: -2%;
    background: green;
    color: white;
    border-radius: 50px;
    transform: rotate(40deg);
}

.submit-pincode-whatsapp {
    font-size: 12px !important;
}

.selected-row-price-border {
    border: 1px solid red !important;
}

.self-pickup-color {
    background: #59c5d1;
    color: white;
}

.unit-price-desktop {
    font-size: 16px !important;
}

.product-share-icon {
    display: none;
}

.product-share-icons {
    display: block;
}

/* ***** For show social icon onclick share button ****** */
.wrapper {
    position: absolute;
    top: 5px;
    right: 160px;
    z-index: 999;
    /* transform: translate(-50%, -50%); */
}

.wrapper ul {
    position: relative;
    /* width: 330px; */
    height: 50px;
    z-index: 999;
}

.wrapper ul li {
    display: inline-block;
    margin: 0 15px;
    position: absolute;
    left: 0;
    top: 0;
    transition: all .5s;
}

.wrapper a {
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    color: #fff;
    font-size: 18px;
    text-align: center;
}

.wrapper li:nth-child(1) {
    z-index: 1;
    background: #333;
}

.wrapper.active li:nth-child(2) {
    left: 50px;
    background: #3b5998;
    transition-delay: 200ms;
}

.wrapper.active li:nth-child(3) {
    left: 100px;
    background: #55acee;
    transition-delay: 400ms;
}

.wrapper.active li:nth-child(4) {
    left: 150px;
    background: #e95950;
    transition-delay: 600ms;
}

.wrapper.active li:nth-child(5) {
    left: 200px;
    background: #cb2027;
    transition-delay: 800ms;
}

.wrapper.active li:nth-child(6) {
    left: 250px;
    background: #dd4b39;
    transition-delay: 1000ms;
}

.product-share-icon {
    position: absolute;
    top: 37px;
    right: 18px;
    list-style-type: unset;
    padding: 10px;
    width: 78%;
    background: #fff;
}
.sticker-share-icon{
    top: -82%;
    right: 1rem;
    width: auto;
    padding: 5px 10px;
}
.sticker-share-icon li{
    display: flex;
    align-items: center;
    margin-right: 5px;
}
.sticker-share-icon i{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 1.3rem;
    background: #ff0000;
    color: #fff;
}
.product-share-icon ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.product-share-icon-img {
    position: absolute;
    top: 5px;
    right: 28px;
    background: #55acee;
    width: 30px;
    padding: 3px 7px;
    height: 30px;
    color: white;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width:500px) {
    .product-share-icon-img {
        position: absolute;
        top: 5px;
        right: 10px;
        background: #55acee;
        width: 30px;
        padding: 3px 7px;
        height: 30px;
        color: white;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.product-share-icon .facebook {
    background: #3b5998;
    transition-delay: 200ms;
    padding: 5px;
    color: white;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.product-share-icon .linkedin {
    background: #0077b5;
    transition-delay: 200ms;
    padding: 5px;
    color: white;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.product-share-icon .instagram {
    background: #df4379;
    transition-delay: 200ms;
    padding: 5px;
    color: white;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.product-share-icon .whatsapp {
    background: #4caf50;
    transition-delay: 200ms;
    padding: 5px;
    color: white;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.product-share-icon .link {
    background: #a5acb9;
    transition-delay: 200ms;
    padding: 5px;
    color: white;
    border-radius: 5px;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}

.fa-share-icon {
    z-index: 1 !important;
}

@media (min-width:820px) {
    .order-payment-order-display {
        display: flex !important;
    }
}

@media (max-width:820px) {
    .order-payment-order-display {
        display: block !important;
    }
}

.payment-mode-height {
    height: 220px;
}


@media (max-width:767px) and (min-width:500px) {
    .shipping-option-tab {
      margin: 0px 15px 0 0px !important;
    }
  
    .payment-option-tab {
      margin: 10px 0 0 0 !important;
    }
  
    .payment-mode-height {
      height: 150px !important;
    }
  }
  
  
  
  @media (min-width:1000px){
    .rightCardOuterorder {
      margin-top: 50px !important;
      padding-bottom: 3px !important;
      margin-bottom: 0 !important;
    }
    .rightCardOuterorder-right{
      margin-top: 70px;
      padding-bottom: 3px;
      margin-bottom: 0;
    }
  }
  
  @media (max-width:1000px) and (min-width:760px){
    .rightCardOuterorder {
      margin-top: 35px !important;
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }
    .rightCardOuterorder-right{
      margin-top: 35px !important;
      padding-bottom: 0 !important;
      margin-bottom: 0 !important;
    }
  }
  
  @media (max-width:760px){
    .rightCardOuterorder {
      margin-top: 35px;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .rightCardOuterorder-right{
      margin-top: 1px;
      padding-bottom: 3px;
      margin-bottom: 0;
    }
    .order-img-large-phn img{
      width: 100% !important;
    }
  }