.home-online-store-products .slick-prev,
.home-online-store-products .slick-next {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 100% !important;
    display: block;
    width: 20px;
    height: 20px;
    padding: 0;
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}

.home-online-store-products .slick-prev {
    /* left: 40% !important; */
    left: 45% !important;
}

.home-online-store-products .slick-next {
    /* right: 40% !important; */
    right: 45% !important;
}

.home-online-store-products .slick-prev:before,
.home-online-store-products .slick-next:before {
    font-size: 30px !important;
}

@media (max-width:500.9px) {
    .section-online-store {
        padding: 30px 0px 10px !important;
    }
}