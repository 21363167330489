/*
  [data-title] {
   position: relative;
  }

  [data-title]:hover::before {
    content: attr(data-title);
    position: absolute;
    bottom: -26px;
    display: inline-block;
    padding: 3px 6px;
    border-radius: 2px;
    background: #000;
    color: #fff;
    font-size: 12px;
    font-family: sans-serif;
    white-space: nowrap;
  }
  [data-title]:hover::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 8px;
    display: inline-block;
    color: #fff;
    border: 8px solid transparent;
    border-bottom: 8px solid #000;
  }
  @media (max-width: 767.9px) and (min-width: 300px){
.mdbtable .col-sm-12{
    overflow-x: scroll !important;
}
.pipe{
  display: none;
}
  }
  @media (max-width: 1200px) and (min-width: 800px){
    .pipe{
      display: none;
    }
  } */

/* .crossbtn-sticker{
    position: absolute;
    right: 0;
    top: -3px;
  } */

.crossbtn-sticker {
  position: relative;
  right: 0;
  top: 5px;
  width: 120px;
  font-size: 26px;
}

.image-prev-box {
  vertical-align: top;
  width: 100%;
  border: 1px solid #ececec;
  border-radius: 8px;
  background: #fff;
  padding: 1rem;
  cursor: pointer;
  box-shadow: 0 2px 3px rgb(0 0 0 / 8%);
  position: relative;
}

.image-prev-box img {
  height: 250px !important;
  width: 100% !important;
  object-fit: contain;
}

.image-prev-box svg {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  font-size: 1.8rem;
  background: #fff;
  border-radius: 8px;
  padding: 2px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.pdf-modal ul {
  display: flex;
  list-style: none;
}

.pdf-modal ul li a {
  font-size: 1.3rem;
  margin-right: 10px;
  background: #59c5d1;
  color: #fff;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.pdf-modal .modal-header i {
  font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
  .crossbtn-sticker {
    position: absolute;
    right: 0;
    top: 5px;
    width: 40px;
    font-size: 26px;
  }
}

/* 20-3-2024 */
.slider-left-arrow-sticker .slick-prev {
  z-index: 1 !important;
}


/* Css for custom box and stciker online component */

@media (max-width:1360px) and (min-width:1120px) {
  .custom-lg-online-heading {
    /* font-size: 15px !important; */
    height: 53px !important;
    text-wrap: wrap !important;
    align-items: center;
    display: flex;
    text-align: start;
    /* overflow-wrap: anywhere;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap; */
    text-align: center;
  }
}

@media (max-width:1120px) {
  .custom-lg-online-heading {
    text-wrap: wrap !important;
    align-items: center;
    display: flex;
    text-align: start;
  }

  .grid-container {
    grid-template-columns: repeat(4, 1fr) !important;
  }
}

@media (max-width:1105px) {
  .custom-lg-online-heading {
    height: 53px !important;
  }
}


/* phone view */
@media (max-width:800px) and (min-width:700px) {
  .custom-phn-online-heading {
    height: 50px !important;
    font-size: 16px !important;
  }
}

@media (max-width:700px) and (min-width:575px) {
  .custom-phn-online-heading {
    font-size: 14px !important;
    height: 35px !important;
  }

  .custom-phn-online-btn {
    font-size: 14px !important;
  }
}

@media (max-width:575px) {

  .custom-phn-online-heading,
  .custom-phn-online-btn {
    font-size: 14px !important;
  }
}

@media (max-width:463px) {
  .custom-phn-online-heading {
    height: 34px;
  }
}

@media (max-width:385px) {

  .custom-phn-online-heading,
  .custom-phn-online-btn {
    font-size: 13px !important;
  }
}

@media (max-width:350px) {

  .custom-phn-online-heading,
  .custom-phn-online-btn {
    font-size: 11px !important;
    text-wrap: wrap !important;
  }

  .custom-phn-online-heading {
    height: 25px;
  }
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}

.grid-item {
  display: flex;
  justify-content: center;
}

.sticker-phn-skelton {
  width: 50% !important;
}

.sticker-online-size {
  height: 185px;
  width: 12rem;
  /* width: 100% !important; */
  margin-top: 10px;
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.sticker-page-material-slider .select-material-item {
  max-width: 75px;
  margin-left: 1.5rem;
}

@media (max-width:400px) {
  .sticker-online-size {
    width: 10rem;
  }
}

@media (max-width:330px) {
  .sticker-online-size {
    width: 9rem;
  }
}

.phn-sticker-btn {
  color: #fff !important;
}

.item-content {
  width: 100%;
}


@media (min-width: 992px) {
  .grid-container {
    grid-template-columns: repeat(5, 1fr);
  }

  .phn-online-custom-box {
    display: none !important;
  }
}

@media (max-width:992px) {
  .desktop-online-custom-box {
    display: none !important;
  }
}




/* ******* Desktop view custom box home ********** */
@media(min-width:701px) {
  .phn-view-custom-box-item {
    display: none !important;
  }
}

@media(max-width:701px) {
  .desktop-view-custom-box-item {
    display: none;
  }
}

.shop-phn-img-heading-home-desktop {
  font-size: 16px;
  align-items: center;
  display: flex;
  text-align: start;
  overflow-wrap: anywhere;
  overflow: hidden;
  /* text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; */
  text-align: center;
  text-transform: capitalize !important;
}

@media (max-width:1090px) and (min-width:1024px) {
  .shop-phn-img-heading-home-desktop {
    height: 53px !important;
  }
}

@media (max-width:700px) {

  .shop-phn-img-heading-phn-home,
  .shop-phn-img-heading-phn-home-btn {
    font-size: 14px !important;
    height: 34px;
  }

  .shop-phn-img-heading-phn-sticker-home,
  .shop-phn-img-heading-phn-sticker-home-btn {
    font-size: 14px !important;
  }
}

@media (max-width:600px) {
  .shop-phn-img-heading-phn-home {
    height: 35px !important;
  }

  .shop-phn-img-heading-phn-home-btn,
  .shop-phn-img-heading-phn-sticker-home-btn {
    text-wrap: wrap !important;
  }
}

@media (max-width:350px) {

  .shop-phn-img-heading-phn-home,
  .shop-phn-img-heading-phn-home-btn,
  .shop-phn-img-heading-phn-sticker-home,
  .shop-phn-img-heading-phn-sticker-home-btn {
    font-size: 10px !important;
  }

  .shop-phn-img-heading-phn-home {
    height: 34px !important;
  }
}

.shop-phn-img-heading-phn-home,
.shop-phn-img-heading-home-desktop,
.shop-phn-img-heading-phn-sticker-home,
.shop-phn-img-heading-phn-sticker-home-btn {
  text-transform: capitalize !important;
}


/* ************** Grid phone view ************* */
.Vertical-wrap {
  max-width: 200px;
}

.Vertical-wrap div {
  width: 100%;
}

.vertical-grid-box {
  display: grid;
  grid-template-columns: repeat(17, 120px);
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: row;
  width: 100%;
  overflow-x: auto;
}

.vertical-grid-box-sticker {
  display: grid;
  grid-template-columns: repeat(17, 120px);
  grid-template-rows: repeat(2, 1fr);
  grid-auto-flow: row;
  width: 100%;
  overflow-x: auto;
}

@media (max-width: 700px) {
  .vertical-grid-box {
    grid-template-columns: repeat(12, 210px);
    grid-template-rows: repeat(2, 1fr);
  }

  .vertical-grid-box-sticker {
    grid-template-columns: repeat(6, 210px);
    grid-template-rows: repeat(2, 1fr);
  }
}

@media (max-width: 480px) and (min-width:420px) {
  .vertical-grid-box {
    grid-template-columns: repeat(12, 185px);
  }

  .vertical-grid-box-sticker {
    grid-template-columns: repeat(6, 185px);
  }

  .Vertical-wrap {
    max-width: 175px !important;
  }

  .shop-phn-img-heading-phn-home,
  .shop-phn-img-heading-phn-home-btn,
  .shop-phn-img-heading-phn-sticker-home,
  .shop-phn-img-heading-phn-sticker-home-btn {
    font-size: 13px !important;
  }
}

@media (max-width:420px) {
  .Vertical-wrap {
    max-width: 140px !important;
  }

  .vertical-grid-box {
    grid-template-columns: repeat(12, 148px);
  }

  .vertical-grid-box-sticker {
    grid-template-columns: repeat(6, 148px);
  }

  .shop-phn-img-heading-phn-home,
  .shop-phn-img-heading-phn-home-btn,
  .shop-phn-img-heading-phn-sticker-home,
  .shop-phn-img-heading-phn-sticker-home-btn {
    font-size: 11px !important;
  }

  .shop-phn-img-heading-phn-home {
    height: 30px !important;
  }

  .shop-phn-img-heading-phn-home-btn {
    height: 45px !important;
  }
}

.white-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: var(--theme-color-white);
  height: 3px;
}

.white-scrollbar::-webkit-scrollbar {
  width: 2px;
  background-color: var(--theme-color-white);
  height: 3px;
}

.white-scrollbar::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: var(--theme-color-white);
  height: 3px;
}

.product-content-align {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-align: center !important;
}

.inner-pio-phn-logo {
  transform: translateY(-10px);
}

.inner-pio-phn-logo img {
  height: 150px !important;
}

.sticker-width-height {
  flex-direction: column !important;
}

@media (min-width:500px) {
  .static-h1-tems-font {
    font-size: 24px !important;
  }
}

@media (max-width:500px) {

  .static-h1-tems-font,
  .custom-sticker-font-size {
    font-size: 18px !important;
  }

}

.custom-sticker-font-size {
  font-size: 30px;
  font-family: "Lato", sans-serif;
  font-weight: 500;
  text-transform: inherit;
}

.inner-page-cont-para P {
  margin: 10px;
}


/*************************************************     blog *********************************************************************/
.home-blog-section .industries-box {
  height: 100%;
  margin-bottom: 0;
}

.home-blog-section .industries-box-txt h2 {
  min-height: 50px;
}

.blog-content {
  padding: 10px !important;
}

.blog-content a {
  background: rgb(89, 197, 209);
  color: #fff;
  font-weight: 700;
}

.blog-content p {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.blog-content svg {
  height: 20px !important;
  margin-right: 5px;
  margin-top: 0;
  color: rgb(89, 197, 209);

}

.blog-main-content svg {
  color: rgb(89, 197, 209) !important;
}

.blog-small svg {
  color: rgb(89, 197, 209) !important;
}

.read-more-button a {
  display: flex;
  width: fit-content;
  background: rgb(89, 197, 209) !important;
  font-weight: 700;
  border-color: none !important;
}


.blog-content {
  display: -webkit-box !important;
  -webkit-line-clamp: 4 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  margin-top: 0;
  /* margin-bottom: 20px !important; */
}

.blog-section .technology-card {
  padding: 0;
  overflow: hidden;
  margin-bottom: 10px;
}

.blog-section .technology-card .technology-card-inner {
  padding: 0;

}

.blog-section .technology-card .technology-card-inner .service-visual {
  margin-bottom: 10px;
}

.blog-content-card {
  padding: 15px;
  padding-top: 0;
}

.blog-section .technology-card {
  text-align: left !important;
}

.blog-content-card span,
.date-content {
  /* color: var(--theme-color-orange) !important; */
  font-weight: 500;
}

.blog-section .blog-content-card svg,
.blog-detail-main .blog-btn-card svg {
  width: 25px !important;
  margin-right: 5px;
}


.blog-detail-main .col-lg-8 img {
  width: 100%;
}

/*  */
.service-page .form-appoinment,
.blog-btn-card {
  border-radius: 10px;
  padding: 1rem;
}

.blog-btn-card ul {
  padding: 0;
}

.blog-btn-card ul li {
  list-style: none;
  align-items: flex-start;
}

.blog-main-content h5 {
  font-weight: 900 !important;
}

.blog-btn {
  width: 100%;
  /* text-align: center; */
  padding: 10px 15px;
  display: inline-block;
  border-radius: 5px;
  /* border: 1px solid #000; */
  margin-bottom: 1rem;
  color: #000;
}

.service-btn.active {
  color: #fff;
  background-color: var(--theme-color-dark);
}

.date-content {
  color: var(--theme-color-orange) !important;
}

.blog-side-image {
  /* width: 150px; */
  width: 25%;
  aspect-ratio: 1/1;
}

.blog-side-image img {
  height: 100%;
}

.blog-side-card {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(20, 21, 21, 0.1);
}

.blog-side-card:last-child {

  border-bottom: none;
}

.blog-detail-main .blog-btn-card .blog-side-card .blog-side-content {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  width: 70%;
}

.blog-detail-main .blog-btn-card .blog-side-content a {
  font-size: 1.2rem;
  font-weight: bolder;
  color: var(--theme-color-dark);
}

.blog-detail-main .blog-btn-card .blog-side-image img {
  object-fit: cover;
}

.blog-main-content p {
  margin-bottom: 10px;
  /* display: flex; */
  /* gap: 10px; */
}

.blog-detail-main .blog-btn-card svg {
  width: 20px !important;
}

.heading-content {
  height: 100px !important;
}

.recent-blogs {
  padding-top: 0;
  transition: .5s;
}

.recent-blogs {
  z-index: 1 !important;
}

.recent-blogs.active {
  padding-top: 80px;
}

.blog-page {

  margin: 0;
}

.blog-page .blog-card h2 {
  min-height: 50px;
  font-size: 20px;
  font-family: "Lato", sans-serif;
  margin-bottom: 10px;
}

.blog-side-content span {
  font-weight: 500;
}

.blog-btn-card span {
  font-weight: 500;
}

.blog-detail-page-banner .page-banner h1 {
  font-size: 2.3rem !important;
}


.view a {
  padding: 1rem 2rem;
}

.text-decoration-underline {
  text-decoration: underline !important;
  text-underline-offset: 3px;
}


.fw-bold {
  font-weight: 900 !important;
}

.bulk {
  font-size: 12px;
}

.blog-card {
  box-shadow: 0 0px 10px 0 rgba(9, 26, 44, 0.2);
}

@media (max-width:568px) {

  .blog-detail-page-banner .page-banner {
    padding-top: 4rem;
    padding-bottom: 2rem;
    height: auto;
  }

  .blog-detail-page-banner .page-banner .bread-crumb li:before {
    line-height: 25px;
  }

  .blog-detail-page-banner .page-banner .bread-crumb li:last-child {
    line-height: 1.5;
  }

  .blog-detail-page-banner .page-banner .bread-crumb {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .blog-detail-page-banner .page-banner h1,
  .blog-detail-main .blog-btn-card .blog-side-content a {
    font-size: 0.9rem !important;
  }

  .recent-blogs {
    position: static !important;
  }

  .recent-blogs.active {
    padding-top: 0;
  }

  .blog-section .react-multi-carousel-list {
    padding: 0;
  }
}