.font-overview {
  font-family: Gilroy-SemiBoldlight !important;
}

.h1-shop {
  font-family: Gilroy-SemiBoldlight !important;
}

.slick-prev,
.slick-next {
  display: "none !important";
}

.sp-bold {
  font-family: Gilroy-medium !important;
}

.how {
  font-family: Gilroy-SemiBold !important;
}

.font-overview p {
  font-family: Gilroy-medium !important;
  font-weight: 200 !important;
  line-height: 18px;
  font-size: 15px !important;
}

.pointplaceorder {
  line-height: 25px !important;
  color: black;
}

.pointplaceorder ul li {
  display: flex;
}

.pointplaceorder ul :before {
  content: "\2713";
  font-size: 15px;
  margin-left: 0px;
  margin-right: 5px;
  font-weight: 600;
  color: #000 !important;
}

.pointplaceordernextsteps {
  line-height: 25px !important;
  color: black;
}

.pointplaceordernextsteps ul {
  list-style: none !important;
}

.pointplaceordernextsteps ul li {
  display: flex;
}

.pointplaceordernextsteps ul :before {
  content: "\1F449";
  font-size: 15px;
  margin-left: 0px;
  margin-right: 5px;
  font-weight: 600;
  color: #000 !important;
}

.product-cat-box-active-border {
  border: 1px solid #59c5d1 !important;
  background: #59c5d1 !important;
}

@media (max-width:500px) {
  .desktop-shop-banner {
    display: none !important;
  }

  .shop-about-paading-mobile-view {
    padding: 10px 0 !important;
  }

  .phone-view-slick-dot-shop-page .slick-dots {
    position: absolute;
    bottom: -10px !important;
    display: block;
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: center;
  }

  .shop-top-content-heading {
    box-shadow: 0 10px 60px 0 rgba(9, 26, 44, 0.3) !important;
  }

  .website-btn {
    width: 100% !important;
  }
}

@media (min-width:500.9px) {
  .mobile-shop-banner {
    display: none !important;
  }
}

.website-btn {
  border-radius: 3px !important;
}

.website-btn:focus {
  outline: none !important;
}

.website-name {
  font-weight: 900;
  font-size: 20px;
  letter-spacing: 2px;
}

.website-subheading {
  font-size: 12px;
}