.globalTrendzEcomm-btn {
  color: #fff;
  background-color: #59c5d1;
  border-color: #59c5d1;
}

.globalTrendzEcomm-btn:hover {
  color: #59c5d1;
  background: white;
  border: 1px #59c5d1 solid;
  border-radius: 3px;
}
.globalTrendzEcomm-text {
  color: #f2f9fa;
}
.globalTrendzEcomm-bg-soft-pink {
  background-color: #3e3e3e;
}

body[data-layout="horizontal"] .container-fluid,
body[data-layout="horizontal"] .container-sm,
body[data-layout="horizontal"] .container-md,
body[data-layout="horizontal"] .container-lg,
body[data-layout="horizontal"] .container-xl,
body[data-layout="horizontal"] .navbar-header {
  max-width: 100%;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f7c4d3;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}

.custom-file-upload {
  border-radius: 5%;
  display: inline-block;
  position: relative;
  padding: 6px;
  cursor: pointer;
  /* background: linear-gradient(270deg, #3fa1a9, #79f1a4); */
  border: 1px solid lightgray;
  margin-bottom: 25px;
}

.custom-file-upload img {
  height: 100%;
  width: 100%;
}

.img-wrap {
  position: relative;
  width: 100px;
  height: 100px;
  overflow: hidden;
  /* border-radius: 50%; */
}
.img-upload:before {
  font-family: "Font Awesome 5 Free";
  content: "\f093";
  font-size: 90px;
  position: absolute;
  padding-top: 45px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #777f80;
  width: 200px;
  height: 200px;
  /* border-radius: 50%; */
  opacity: 0;
  transition: 0.5s ease;
  background-color: #fff;
}
.img-upload:hover:before {
  opacity: 1;
}

input[type="file"] {
  display: none;
}

.dash {
  height: 105px;
}

/* table.mdb-dataTable thead, table thead.mdb-dataTable-head {
    cursor: pointer;
    background: #f8f9fa!important;
    /* text-align: center!important; */
/* } */
*/

/* 
table.mdb-dataTable thead, table thead.mdb-dataTable-head:nth-of-type(8) {
  background: red;
} */
.table-striped tbody tr {
  background: #fff !important;
  text-align: center !important;
}

/* button.btn-rounded.globalTrendzEcomm-btn.waves-effect.waves-light.text-center.btn.btn-secondary.btn-sm{
  position: relative;
  left: 44px;
} */

/* .max-dashbtn{
  position: relative;
  left: 46px;
} */

.categoryCard {
  border: 1px solid #f8f8fb;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 5px;
  margin-top: 10px;
}

.imagecard {
  margin-top: -13px;
}

.categoryimage {
  height: 150px;
  width: 150px;

  border: 1px solid #f8f8fb;
  padding: 2px;
  margin-bottom: 15px;
}

.categoryimage2 {
  /* height: 100px; */
  width: 100px;
  border: 1px solid #f8f8fb;
  padding: 2px;
  margin-bottom: 13px;
}

.card-row {
  border-bottom: 1px solid #f8f8fb;
}
.deleteicnbtn {
  float: right;
  margin-top: 5px;
  color: #74788d;
}
.categorystatus {
  margin-top: 15px;
}

.categorydesc {
  text-align: justify;
  padding-left: 25px;
}

.subcategoryname {
  color: #74788d;
}
.actionuser {
  position: relative;
  right: 10px;
}

.usercarddata {
  border: 1px solid #f8f8fb;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  -webkit-box-shadow: 2px 2px 5px 1px #e1e1e1;
  box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
}

.usertitle {
  margin-top: 15px;
}
.identi {
  height: 250px;
}
.info {
  height: 530px;
}
.userinfo2 {
  height: 175px;
}

/* body{bacbackground-color: white!important;} */
/* @media only screen and (max-width: 1024px) and (min-width: 360px) { */

/* body{overflow:scroll}} */

tr.danger td {
  background-color: #f7f7f7 !important;
  -webkit-print-color-adjust: exact;
}

.mdb-datatable-filter.flex-row {
  display: none;
}

tbody + thead {
  display: none;
}

.page-item.active .page-link {
  z-index: 1;
  color: #fff;
  background-color: #777f80 !important;
  border-color: #777f80 !important;
}

/* ========================================SideBar  start================================== */

@media screen and (max-width: 800px) {
  .sidebar {
    display: none;
  }
}
@media screen and (min-width: 800px) {
  .toggle-icon {
    display: none;
  }
}

.profile-circle {
  background-color: white;
  width: 40%;
  height: auto;
  border-radius: 50%;
  align-self: center;
  align-content: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  overflow: hidden;
}
.profile-img {
  height: 100%;
  width: 100%;
  overflow: hidden;
  border: 3px solid grey;
  border-radius: 86%;
}
.user-name {
  align-self: center;
  text-align: center;
  color: "#f8f8fb";
}
.user-name .h5 {
  color: "#f8f8fb";
}
.pro-sidebar {
  color: #adadad;
  height: 100%;
  width: 270px;
  min-width: 270px;
  text-align: left;
  transition: width, left, right, 0.3s;
  position: relative;
  margin-left: -23px;
  z-index: 0 !important;
}
.pro-sidebar-header {
  border-radius: 25px;
}
.sidebar-heading {
  font-size: 20px;
  color: #333333;
}
.sidebar {
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 25px;
}

/* ===============================Sidebar end=========================================== */

/* ==========================Sidebar Address start =================================*/

.address-card {
  width: 100%;
  height: auto;
  -webkit-box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
}
.address-content {
  text-align: left;
}
.address-content p {
  font-size: 100%;
}
.address-icon-row {
  margin-bottom: 0px;
}
.address-box-profile {
  border: 1px solid #eeeeee;
  padding: 60px;
}
@media screen and (max-width: 760px) {
  .address-box-profile {
    height: 120%;
  }
}
.address-plus {
  text-align: center;
}
.address-icon {
  color: #495057;
}
.address-icon:hover {
  color: black;
}

/* ===========================Sidebar Address end================================= */

/* =================================Address Modal start================================*/
.address-modal {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #f6f6f6;
  border-radius: 0.4rem;
  outline: 0;
  margin-right: auto;
  margin-left: auto;
  align-self: center;
}
.react-responsive-modal-modal {
  max-width: 90%;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #ffffff;
  box-shadow: 0 12px 15px 0 rgb(0 0 0 / 25%);
  margin: 1.2rem;
  padding: 1.2rem;
  position: relative;
  overflow-y: auto;
  margin-top: 9%;
}
@media screen and (max-width: 600px) {
  .react-responsive-modal-modal {
    margin-top: 15%;
  }
}
/*============================== Address Modal end ===================================*/

/* =============================Help Center======================================== */
.nav-link-style.nav-link-light {
  color: rgba(255, 255, 255, 0.65);
}
.fs-sm .border-light {
  border-color: rgba(255, 255, 255, 0.12) !important;
}
.fs-sm .border-bottom {
  border-bottom: 1px solid #e3e9ef !important;
}
.nav-link-style.nav-link-light:hover {
  color: #fff;
}
.btn-outline-primary {
  color: #777f80;
  border-color: #777f80;
}
.btn-outline-primary:hover {
  background-color: #777f80;
  border-color: #777f80;
}
.handshake-icon {
  justify-content: center;
  align-self: center;
  margin-left: auto;
  margin-right: auto;
}
/* =================================Help Center End===================================== */

/* ================================Submit Request=================================== */

.bg-secondary {
  background-color: #f6f9fc !important;
}
.alert-info {
  color: #59c5d1;
  background-color: #f0f7ff;
  border-color: #cbe4ff;
}
.alert {
  position: relative;
  padding: 0.75rem 1.125rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.4375rem;
}
.alert-info .alert-icon {
  background-color: rgba(105, 179, 254, 0.1);
}
.alert-icon {
  display: flex;
  align-items: center;
  margin-top: -0.75rem;
  margin-right: 1.125rem;
  margin-bottom: -0.75rem;
  margin-left: -1.125rem;
  padding: 0.75em;
  border-top-left-radius: 0.4375rem;
  border-bottom-left-radius: 0.4375rem;
  font-size: 1.125rem;
}
.fs-sm {
  font-size: 0.875rem !important;
}
@media (min-width: 992px) {
  .offcanvas-collapse {
    display: block;
    position: static;
    top: auto !important;
    right: auto !important;
    bottom: auto !important;
    left: auto !important;
    width: 100% !important;
    height: auto !important;
    transform: none !important;
    background-color: transparent;
  }
}
.offcanvas {
  will-change: transform, box-shadow;
  transition: transform 0.4s cubic-bezier(0.165, 0.84, 0.44, 1),
    box-shadow 0.3s ease;
  box-shadow: none;
  visibility: visible !important;
}
.border-end {
  border-right: 1px solid #e3e9ef !important;
}
@media (min-width: 992px) {
  .offcanvas-collapse .offcanvas-header,
  .offcanvas-collapse .offcanvas-footer {
    display: none;
  }
}
.offcanvas-header,
.offcanvas-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
  padding: 1.25rem 1.5rem;
}
@media (min-width: 992px) {
  .offcanvas-collapse .offcanvas-body {
    padding: 0;
    overflow: initial;
  }
}
.offcanvas-body {
  height: 100%;
}
.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}
.simplebar-track.simplebar-horizontal {
  height: 0.1875rem !important;
}
.offcanvas-body > .simplebar-track {
  display: block;
  background-color: transparent;
}
.simplebar-track.simplebar-vertical {
  width: 0.1875rem !important;
}
.offcanvas-body .simplebar-vertical {
  margin-right: 0.1875rem;
}
.offcanvas-body > .simplebar-track {
  display: block;
  background-color: transparent;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar {
  right: 0;
  width: 0.1875rem;
}
.simplebar-track .simplebar-scrollbar {
  border-radius: 0.09375rem;
  background-color: #aeb4be;
}
.widget-list-item {
  margin-bottom: 0.5rem;
}
.widget-list-link {
  display: block;
  transition: color 0.25s ease-in-out;
  color: #4b566b;
  font-size: 0.875rem;
  font-weight: normal;
}
.widget-list-link:hover {
  color: #777f80;
  font-size: 0.88rem;
}
.nav-link-style {
  color: #4b566b;
}

.login-icon-change-pass {
  float: right;
  bottom: 32px;
  position: relative;
  right: 9px;
}

.cardbackground {
  background: white;
  margin-top: 20px;
  box-shadow: 2px 2px 5px 1px #e1e1e1;
  margin-bottom: 20px;
}

.pro-sidebar-inner {
  border-radius: 3%;
}
.btnicons {
  width: 20px;
}

.slider-responsive {
  width: 50%;
  height: 100%;
  margin-right: 5px;
  margin-left: 5px;
}

.best-selling-products {
  padding: 20px 0;
}

.img-container {
  padding-top: 22px;
  padding-left: 22px;
}

.img-container img {
  width: 85%;
}

.best-products .product-item {
  padding: 10px;
  border: 1px solid #f0f0f0;
  align-content: center;
  align-self: center;
  margin: 5px;
  background-color: #fff;
}

.product-desc h4 {
  text-align: center;
  padding-top: 5px;
  font-size: 15px;
}

.slick-slider.cardslider.slick-initialized {
  background-color: white !important;
}
h4.mb-0.productTitle {
  /* margin-left: -18px; */
  padding: 28px;
  color: #fff;
  /* text-align: center; */
  /* margin-top: -1px; */
}

.address-card {
  width: 100%;
  height: 100%;
  max-height: 245px;
    box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
   /* overflow-y: scroll; */
}

.address-box-profile {
  border: 1px solid #eeeeee;
  padding: 60px;
  height: 96%;
  width: 100%;
  max-height: 245px;
  box-shadow: 0 0 35px 0 rgb(154 161 171 / 15%);
}

.react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: none !important;
}

@media screen and (max-width: 768px) {
  .globalTrendzEcomm-bg-soft-pink {
    height: auto !important;
  }
  h4.mb-0.productTitle {
    padding: 1rem;
    font-size: 1.2rem;
  }
  .input-group-text {
    font-size: 14px;
  }
}
